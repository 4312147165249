import WorkshopsPage from '../../views/workshops';
import { graphql } from 'gatsby';

export default WorkshopsPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    workshops: allContentfulWarsztaty(sort: { order: ASC, fields: data }) {
      edges {
        node {
          title
          type
          link
          place
          slug
          description {
            description
          }
          data
          thumbnail {
            title
            file {
              url
            }
          }
          node_locale
          agenda {
            startTime
            panelLength
            icon
            isBreak
            name
            speaker {
              id
            }
            speakers {
              id
              name
            }
          }
          button
        }
      }
    }
  }
`;
